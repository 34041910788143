import { makeStyles } from '@material-ui/core';

const useFullTypography = makeStyles(() => ({
  root: {
    fontSize: 12,
    textDecoration: 'line-through',
    textTransform: 'uppercase',
    marginRight: 5,
  },
}));

const useProductTypography = makeStyles({
  root: {
    fontSize: 12,
  },
});

const useBagProductTypography = makeStyles({
  root: {
    display: '-webkit-box',
    fontSize: 14,
    lineHeight: '16px',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    width: '100%',

    '@media(orientation: portrait)': {
      width: '95%',
    },
  },
});

const useProductTypographyDesktop = makeStyles(() => ({
  root: {
    fontSize: 14,
    width: '100%',
  },
}));

const useDetailsTypography = makeStyles(() => ({
  root: {
    fontSize: 14,
  },
}));

const useDetailsButton = makeStyles((theme) => ({
  text: {
    fontSize: 14,
  },
  root: {
    fontSize: 14,
    fontWeight: '400',
    textDecoration: 'underline',
    marginTop: 1,
    marginBottom: 10,
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
    color: theme.palette.primary.main,
  },
}));

const useCardStyle = makeStyles(() => ({
  root: {
    width: '100%',
    padding: 0,
  },
  content: {
    width: '100%',
  },
}));

const useDetailsStyle = makeStyles((theme) => ({
  container: {
    padding: 20,
    paddingTop: 0,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 12,
    margin: 0,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 15,
    margin: '0 0 12px 0',
  },
}));

const useDialogActionStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: 0,
  },
}));

const useCloseModalSizeIconStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
}));

const useCloseIconButtonStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    margin: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const useGridSizeStyles = makeStyles((theme) => ({
  root: {
    width: 40,
    height: 40,
    border: `1px solid ${theme.palette.secondary.dark} `,
    borderRadius: theme.products.size.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const useBagGridStyles = makeStyles(() => ({
  root: {
    margin: 0,
    marginRight: 20,
    minHeight: 70,
    width: '100%',
    '@media(orientation: portrait)': {
      width: 'calc(100vw - 50px)',
    },
  },
}));

const useDeleteIconStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.secondary.dark} `,
    borderRadius: theme.products.icon.shape.borderRadius,
    top: '0',
    right: '0',
    backgroundColor: '#FFF',
    zIndex: '2',
    position: 'absolute',
    width: '40px',
    height: '40px',
    '@media(orientation: portrait)': {
      left: 'calc(100vw - 70px)',
    },
  },
}));

const useProdButtonStyles = (theme, isBag) => ({
  bagProd: {
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    width: isBag ? 92 : 'auto',
    height: isBag ? 140 : '100%',

    '& span,& div': {
      height: '100%',
    },
  },
  deleteProd: {
    border: 'none',
    minWidth: '100%',
    padding: 0,
    marginLeft: 68,

    '@media(orientation: landscape)': {
      marginLeft: 68,
    },
  },
});

const useClusterTagStyles = () => ({
  notOnBag: {
    pointerEvents: 'none',
    width: 'fit-content',
    position: 'absolute',
    padding: '0 8px',
    height: 'auto',
    bottom: 5,
    left: 8,
  },
  onBag: {
    pointerEvents: 'none',
    width: 'fit-content',
    padding: '4px 12px',
    borderRadius: '4px',
    lineHeight: '16px',
    marginBottom: 20,
    height: 'auto',
    fontSize: 12,
  },
});

const useGridContainerSizeStyles = makeStyles(() => ({
  root: {
    height: 100,
    width: 255,
    marginTop: 30,

    '@media(orientation: portrait)': {
      height: 100,
      width: 'calc(100% - 108px) !important',
      marginTop: 30,
    },
  },
}));

const useGridContainerProductNameStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const useGridContainerBagInfoSizeStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 40,

    '@media(orientation: portrait)': {},
  },
}));

const useSizeStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
    border: `1px solid ${theme.palette.secondary.dark}`,
    width: 40,
    height: 40,
    padding: 18,
    marginRight: 12,

    '@media(orientation: portrait)': {},
  },
}));

export default {
  useFullTypography,
  useDetailsButton,
  useProdButtonStyles,
  useProductTypography,
  useCardStyle,
  useDetailsTypography,
  useProductTypographyDesktop,
  useDetailsStyle,
  useDialogActionStyle,
  useCloseModalSizeIconStyles,
  useCloseIconButtonStyle,
  useBagProductTypography,
  useDeleteIconStyles,
  useBagGridStyles,
  useGridSizeStyles,
  useClusterTagStyles,
  useGridContainerSizeStyles,
  useGridContainerProductNameStyles,
  useGridContainerBagInfoSizeStyles,
  useSizeStyles,
};
