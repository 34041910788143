import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Grid, Typography } from '@material-ui/core';
import SelectSize from '../../molecules/SelectSize';
import SomaInstallmentText from '../../molecules/InstallmentText';
import Styles from './styles';
import { ProductDescription, SomaImage } from '../../atoms';
import { MeasureTable, ProductPrice, SomaCarousel } from '../../molecules';
import { addToBag as addToBagHelper } from '../../../helpers/bagHelper';
import { LocalStorageContext } from '../../../hooks/contexts';
import { useBagContext } from '../../../contexts/bagContext';
import { useSnackbarContext } from '../../../contexts/snackBarContext';
import ProductInfoBar from '../ProductInfoBar';
import { somaEvents, useEventDispatch } from '../../../events';
import TextButton from '../../molecules/SomaClickable/textButton';
import ButtonShareProduct from '../../molecules/SomaClickable/figureButton';
import { getDevice } from '../../../helpers/eventHelper';
import config from '../../../config';
import { SomaTag } from '../../atoms/SomaTag';

const ProductDetails = (props) => {
  const { product, closeDrawer, category } = props;

  const { productName, price, items, images } = product;

  const imagesStd = useRef(Array.from(images.std));
  const imagesHd = useRef(Array.from(images.hd));
  const showAllDetails = product?.description[0] || product?.composition[0];

  const scrollTarget = useRef(null);
  const [selectedSize, setSelectedSize] = useState();
  const [pictureViewing, setPictureViewing] = useState(imagesStd.current[0]);
  const [pictureToZoom, setPictureToZoom] = useState(imagesHd.current[0]);
  const userStorage = useContext(LocalStorageContext);
  const { sendSnack } = useSnackbarContext();
  const { setBagState } = useBagContext();
  const dispatchEvent = useEventDispatch();

  const theme = useTheme();
  const portrait = useMediaQuery('(orientation: portrait)');
  const nameClasses = Styles.useNameStyle(theme);
  const gridClasses = Styles.useGridStyle();
  const buttonClasses = Styles.useButtonStyle(theme);
  const renderDetailGridClasses = Styles.useRenderDetailGridStyle();
  const imageGridClasses = Styles.useImageGridStyle();
  const detailsGridClasses = Styles.useDetailsGridStyle();
  const detailsClasses = Styles.useDetailsStyle(theme);
  const FigureButtonMobileClasses = Styles.FigureButtonMobileStyle(theme);
  const shareIconClasses = Styles.useShareIconStyle(theme);
  const shareIconMobileClasses = Styles.useshareIconMobileStyle(theme);
  const gridButtonShareClasses = Styles.useGridButtonShareStyles();
  const clusterTagClasses = Styles.useClusterTagClasses(portrait);
  const FigureButtonClasses = Styles.useFigureButtonStyles(theme);

  const regExp1 = /v=[0-9]+/;

  const getPictureId = (img) => regExp1.exec(img)[0];
  const [imgWidth, imgHeight] = portrait ? [300, 400] : [402, 603];

  const onSelectPicture = (picture) => {
    const pictureId = getPictureId(picture);

    setPictureViewing(picture);
    setPictureToZoom(images.hd.find((item) => item.includes(pictureId)));
  };

  const addProductToBag = () => {
    if (!selectedSize || selectedSize === 'notSelected') {
      setSelectedSize('notSelected');
      return scrollTarget.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const selectedItem = product.items.find((item) => item.size === selectedSize);
    const products = addToBagHelper(product, selectedItem, userStorage);
    setBagState({ products });
    sendSnack(theme.products.successBag.text, 'success');
    dispatchEvent(somaEvents.onProductAddedToCart, {
      category,
      product,
    });
    return closeDrawer();
  };
  const addToBagProps = {
    label: theme.products.button.text,
    buttonProps: { onClick: addProductToBag, disabled: !product.listPrice },
    labelStyles: { ...buttonClasses.label },
    buttonStyles: { ...buttonClasses.primary },
  };

  useEffect(() => {
    imagesStd.current = Array.from(images.std ? images.std : images);
    imagesHd.current = Array.from(images.hd ? images.hd : images);
    setPictureViewing(imagesStd.current[0]);
    setPictureToZoom(imagesHd.current[0]);
  }, [images]);

  const dispatchProductNameOnShare = useCallback(() => {
    dispatchEvent(somaEvents.onShareProduct, { productName });
  }, [dispatchEvent, productName]);

  const shareUrl = useMemo(() => {
    const currentHref = new URL(window.location.href);
    currentHref.searchParams.set(config.sharedProductNavigationParam, product.provider.reference);
    return currentHref.toString();
  }, [product]);

  const shareProductUrlMobile = useCallback(() => {
    const data = {
      url: shareUrl,
    };

    window.navigator.share(data).catch((err) => {
      dispatchEvent(somaEvents.onError, {
        message: 'Failed on sharing mobile product',
        path: 'ProductDetails/index -> on window.navigator.share shareProductUrlMobile()',
        device: getDevice(),
        stack: err.stack,
      });
    });
  }, [dispatchEvent, shareUrl]);

  const copyProductShareLinkToClipboard = useCallback(() => {
    try {
      sendSnack('link copiado!', 'success');
      window.navigator.clipboard.writeText(shareUrl);
    } catch (err) {
      dispatchEvent(somaEvents.onError, {
        message: 'Failed on sharing desktop product',
        path: 'ProductDetails/index -> on window.navigator.clipboard.writeText copyProductShareLinkToClipboard()',
        device: getDevice(),
        stack: err.stack,
      });
    }
  }, [dispatchEvent, sendSnack, shareUrl]);

  const shareProductMobile = () => {
    shareProductUrlMobile();
    dispatchProductNameOnShare();
  };

  const shareProductDesktop = () => {
    copyProductShareLinkToClipboard();
    dispatchProductNameOnShare();
  };

  const renderShareButton = () => {
    if (portrait) {
      return (
        <ButtonShareProduct
          figure={<Typography classes={shareIconMobileClasses} onClick={shareProductMobile} />}
          buttonStyles={FigureButtonMobileClasses}
          buttonProps={{
            disableRipple: true,
            disableFocusRipple: true,
          }}
        />
      );
    }
    if (!portrait) {
      return (
        <ButtonShareProduct
          figure={<Typography classes={shareIconClasses} onClick={shareProductDesktop} />}
          buttonStyles={FigureButtonClasses}
          buttonProps={{
            disableRipple: true,
            disableFocusRipple: true,
          }}
        />
      );
    }
    return null;
  };

  const renderImageAndCarousel = () => (
    <Grid
      item
      container
      justify="center"
      alignContent="center"
      alignItems="center"
      xs={12}
      md={7}
      lg={7}
      classes={imageGridClasses}
    >
      {portrait && renderShareButton()}

      {!portrait && (
        <SomaImage
          key={pictureViewing}
          src={pictureViewing}
          imageOnZoom={pictureToZoom}
          width={imgWidth}
          height={imgHeight}
        />
      )}
      <Box style={{ position: 'relative' }}>
        <SomaCarousel imagesArray={imagesStd.current} imageViewing={pictureViewing} onSelectPicture={onSelectPicture} />
        {portrait && (
          <ProductInfoBar
            product={product}
            addToBagProps={{
              ...addToBagProps,
              buttonStyles: {
                ...buttonClasses.primary,
                ...buttonClasses.secondary,
                backgroundColor: theme.pdp.ctaButtonMobileBG,
                border: 'none',

                '&:hover': {
                  backgroundColor: theme.pdp.ctaButtonMobileBG,
                },
              },
              labelStyles: { ...buttonClasses.label, color: theme.pdp.ctaButtonMobileColor },
            }}
            gridStyles={{ position: 'absolute', backgroundColor: 'transparent', borderTop: 'none' }}
            productNameStyles={{
              color: theme.palette.secondary.main,
              ...Styles.useProductNameStyle,
            }}
            priceStyles={{
              color: theme.palette.secondary.main,
              lineHeight: '16px',
            }}
            gridPriceStyle={{ marginBottom: 5, lineHeight: '16px' }}
            installmentStyle={{ color: theme.palette.secondary.main, lineHeight: '16px' }}
            discontPriceColor={{ color: theme.palette.secondary.main }}
            textsFontSize={theme.pdp.productNameMobileSize}
          />
        )}
      </Box>
    </Grid>
  );

  const renderClusterTag = (productInfo) => {
    const { productClusters, price: productPrice } = productInfo;
    const clusterList = Object.values(productClusters);

    if (!productInfo || !productPrice) return <></>;

    if (clusterList.includes('lojix-exclusivo'))
      return (
        <SomaTag
          label={theme.somaTag.exclusive.text}
          tagStyles={{
            backgroundColor: theme.somaTag.exclusive.backgroundColor,
            ...clusterTagClasses,
          }}
        />
      );

    if (clusterList.includes('queridinho-voltou'))
      return (
        <SomaTag
          label={theme.somaTag.mostLiked.text}
          tagStyles={{
            backgroundColor: theme.somaTag.mostLiked.backgroundColor,
            ...clusterTagClasses,
          }}
        />
      );

    return <></>;
  };

  const renderProductDetails = () => (
    <Grid
      item
      container
      xs={12}
      md={imagesStd.current.length ? 5 : 12}
      lg={imagesStd.current.length ? 5 : 12}
      alignItems="flex-start"
      classes={detailsGridClasses}
    >
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        classes={renderDetailGridClasses}
        container
        direction="column"
        alignItems="flex-start"
      >
        {!portrait && (
          <>
            <Typography noWrap classes={nameClasses}>
              {productName}
            </Typography>
            <ProductPrice product={product} priceStyles={{ color: theme.palette.primary }} fontSize={14} />
            <SomaInstallmentText value={price} align="left" product={product} fontSize={theme.pdp.productPriceSize} />
          </>
        )}
        {renderClusterTag(product)}
        <SelectSize
          items={items}
          scrollTarget={scrollTarget}
          setSelectedSize={setSelectedSize}
          selectedSize={selectedSize}
        />
        <MeasureTable product={product} portrait={portrait} />
        {showAllDetails && (
          <Box className={detailsClasses.container}>
            <Typography className={detailsClasses.title}>{theme.products.details.title}</Typography>
            <ProductDescription description={product.description} />
            <Typography className={detailsClasses.text}>{product.composition}</Typography>
          </Box>
        )}

        {!portrait && (
          <Grid classes={gridButtonShareClasses}>
            <TextButton {...addToBagProps} />
            {renderShareButton()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid item container xs={12} direction="row" alignContent="center" classes={gridClasses}>
      {imagesStd.current.length ? renderImageAndCarousel() : <Box />}
      {renderProductDetails()}
      {portrait && (
        <ProductInfoBar
          product={product}
          addToBagProps={addToBagProps}
          priceStyles={{ color: theme.palette.primary.light, lineHeight: 1 }}
          installmentStyle={{ lineHeight: 1 }}
          gridPriceStyle={{ margin: 0, lineHeight: 1 }}
          fullInstallmentText={false}
          productNameStyles={{
            lineHeight: !product.listPrice ? '16px' : 1,
          }}
        />
      )}
    </Grid>
  );
};

export default ProductDetails;
