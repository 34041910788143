import { makeStyles } from '@material-ui/core';

const useContainerStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 420,
    height: 48,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 100,

    '@media(orientation: portrait)': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 48,
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 100,
    },
  },
}));

const useTitleStyle = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 10,
    fontWeight: 400,
    overflowWrap: 'break-word',
    padding: '20px 8px 20px 9px',

    '@media(orientation: portrait)': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 10,
      fontWeight: 400,
      overflowWrap: 'break-word',
      padding: '20px 20px 20px 16px',
    },
  },
}));

export default { useContainerStyle, useTitleStyle };
