import { AppBar, Box, Collapse, Grid, Tab, Tabs, Typography, useMediaQuery, useScrollTrigger } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTheme } from '@material-ui/core/styles';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import config from '../../../config';
import { ProductsPanelContextProvider, useProductPanelContext } from '../../../contexts/productPanelContext';
import { somaEvents, useEventDispatch } from '../../../events';
import { FilterProducts } from '../../molecules';
import ProductSearch from '../../molecules/ProductSearch';
import { TextButton } from '../../molecules/SomaClickable';
import SomaSalesCoupon from '../SalesCoupon/SomaSalesCoupon';
import SomaGridList from './SomaGridList';
import TabPanel from './SomaTabPanel';
import Styles from './styles';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function ProductsList(props) {
  const { buttons = [], columns, user, scrollTarget } = props;
  const theme = useTheme();
  const { productsPanelState } = useProductPanelContext();
  const { productsFiltered: productsFilter, errorOnSearch } = productsPanelState;
  const [showScrollbutton, setShowScrollbutton] = useState(false);
  const [categoryButton, setCategoryButton] = useState();
  const buttonsRefs = useMemo(() => [], []);
  const portrait = useMediaQuery('(orientation: portrait)');
  const [value, setValue] = useState(0);

  const allProducts = useMemo(
    () =>
      buttons
        .map((button) => button.products)
        .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []),

    [buttons]
  );

  useEffect(() => {
    if (buttons.length) {
      const filteredButtons = buttons.map((button) => {
        const filteredProducts = button.products.filter(
          (product, index, array) =>
            array.findIndex((p) => p.provider.reference === product.provider.reference) === index
        );
        return { ...button, products: filteredProducts };
      });
      filteredButtons.sort((a, b) => parseFloat(a.x) - parseFloat(b.x));

      setCategoryButton(filteredButtons);
    }
  }, [buttons]);

  const tabClasses = Styles.useTabStyles();
  const filterProductGridClasses = Styles.useFilterProductGridStyles();
  const gridListClasses = Styles.useGridListStyles();
  const arrowStyles = Styles.arrowStyles();
  const buttonClasses = Styles.useButtonStyles(theme);
  const errorTextClasses = Styles.errorTextStyles(theme);
  const erroTextContainerClasses = Styles.useErroTextContainerStyles();

  const errorImage = theme.errorOnSearch.icon.src;

  const TabScrollArrowButton = forwardRef((arrowProps, ref) => {
    const { direction, disabled, ...other } = arrowProps;
    const rightArrow = `${config.assetsUrl}/common/icons/right-arrow.svg`;
    const leftArrow = `${config.assetsUrl}/common/icons/left-arrow.svg`;

    return (
      <ButtonBase
        classes={arrowStyles}
        component="div"
        ref={ref}
        {...other}
        style={{ height: 32, opacity: disabled ? 0 : 1 }}
      >
        {direction === 'left' ? (
          <img src={leftArrow} className="leftArrow" alt="Esquerda" />
        ) : (
          <img src={rightArrow} className="rightArrow" alt="Direita" />
        )}
      </ButtonBase>
    );
  });

  const showSearchField = (portrait && value > 0) || !portrait;

  const dispatchEvent = useEventDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isTargetScrolled = useScrollTrigger({ target: scrollTarget?.current ?? window, disableHysteresis: true });
  useEffect(() => {
    setShowScrollbutton(isTargetScrolled);
  }, [isTargetScrolled]);

  const scrollListener = useCallback(() => {
    const scrollHeight = buttonsRefs[value].current?.scrollTop;
    if (scrollHeight > 400) {
      setShowScrollbutton(true);
    } else if (scrollHeight <= 400) {
      setShowScrollbutton(false);
    }
  }, [buttonsRefs, value]);

  useEffect(() => {
    const selectedButtonRef = buttonsRefs[value];
    if (!selectedButtonRef) return;

    setShowScrollbutton(false);
    if (selectedButtonRef.current) {
      selectedButtonRef.current.addEventListener('scroll', scrollListener);
    }
  }, [value, buttonsRefs, scrollListener, categoryButton]);

  const onClickScrollTop = () => {
    setShowScrollbutton(false);
    const actualScroll = portrait ? scrollTarget : buttonsRefs[value];
    return actualScroll.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const productSearchClasses = Styles.productSearchGridStyles();
  const categoryGridClasses = Styles.categoryGridStyles();

  return (
    <>
      <AppBar {...theme.somaNavigation.appBar} position="relative">
        <Grid container justify="center">
          <Collapse in={showSearchField}>
            <Grid item classes={productSearchClasses}>
              <ProductSearch productsData={buttons} allProducts={allProducts} />
            </Grid>
          </Collapse>
          <Grid container item xs={12} classes={categoryGridClasses}>
            <Tabs
              value={value ?? 0}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs categories buttons"
              ScrollButtonComponent={TabScrollArrowButton}
              TabIndicatorProps={{ style: { display: 'none' } }}
              style={{ height: '100%' }}
            >
              {categoryButton &&
                categoryButton.map((button, index) => (
                  <Tab
                    label={button.label}
                    {...a11yProps(index)}
                    key={button.id_button}
                    classes={tabClasses}
                    onClick={() => {
                      dispatchEvent(somaEvents.onProductCategoryViewed, { category: button.label });
                    }}
                  />
                ))}
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>
      {categoryButton &&
        categoryButton.map((button, index) => {
          const isShowcasing = button.id_button === 'showcasing';
          const showCoupon = button.products.length === 0 && isShowcasing;
          buttonsRefs.push(React.createRef());

          return (
            <TabPanel value={value ?? 0} index={index} key={button.id_button}>
              {showCoupon ? (
                <Box display="flex" justifyContent="center">
                  <SomaSalesCoupon />
                </Box>
              ) : (
                <>
                  {!isShowcasing && button.filters && (
                    <Grid item container justify="flex-end">
                      <Grid item classes={filterProductGridClasses}>
                        <FilterProducts filterOptions={button.filters} categoryProducts={button.products} />
                      </Grid>
                    </Grid>
                  )}
                  {errorOnSearch && !productsFilter.length && !isShowcasing ? (
                    <Grid classes={erroTextContainerClasses}>
                      <img src={errorImage} style={errorTextClasses.img} alt="" />
                      <Typography style={errorTextClasses.h1}>{theme.errorOnSearch.errorTextPrimary}</Typography>
                      <Typography style={errorTextClasses.p}>{theme.errorOnSearch.errorTextSecondary}</Typography>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="center"
                      classes={gridListClasses}
                      ref={portrait ? null : buttonsRefs[index]}
                    >
                      <SomaGridList
                        category={button.label}
                        cols={columns}
                        products={isShowcasing || !productsFilter ? button.products : productsFilter}
                        user={user}
                        isShowcasing={isShowcasing}
                        scrollTarget={portrait ? scrollTarget : buttonsRefs[index]}
                      />
                    </Grid>
                  )}
                  {showScrollbutton && (
                    <TextButton
                      label={theme.SomaIconButton.text}
                      buttonStyles={{ ...buttonClasses.button }}
                      labelStyles={{ ...buttonClasses.label }}
                      gridStyles={{ ...buttonClasses.grid }}
                      buttonProps={{
                        onClick: onClickScrollTop,
                        endIcon: <ExpandLessIcon style={{ ...buttonClasses.endIcon }} />,
                      }}
                    />
                  )}
                </>
              )}
            </TabPanel>
          );
        })}
    </>
  );
}

export default function SomaProductsList(props) {
  return (
    <ProductsPanelContextProvider>
      <ProductsList {...props} />
    </ProductsPanelContextProvider>
  );
}
