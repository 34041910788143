import { Grid, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { usePrismicDocuments } from '@prismicio/react';
import { SalesWomanContext } from '../../../hooks/contexts';
import { GetPrismicDocument } from '../../../services/prismic';
import { useStreamContext } from '../../../contexts/streamContext';

const SomaSalesCoupon = () => {
  const { saleswomanCouponCode } = useContext(SalesWomanContext) || {};

  const theme = useTheme();
  const [prismicCoupon, setPrismicCoupon] = useState();
  const [response] = usePrismicDocuments();
  const { streamContent } = useStreamContext();

  useEffect(() => {
    const coupons = {
      default: GetPrismicDocument(streamContent, response)?.data.cupom_default.url,
      vendedora: GetPrismicDocument(streamContent, response)?.data.cupom_vendedora.url,
    };
    setPrismicCoupon(coupons);
  }, [streamContent, response]);

  const couponImage = saleswomanCouponCode ? prismicCoupon?.vendedora : prismicCoupon?.default;

  const renderSaleswoman = () => (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={couponImage}
          style={{
            padding: 0,
            textAlign: 'center',
            width: '100%',
          }}
          alt="Logo"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '2.25rem',
            lineHeight: '1.25',
            textAlign: 'center',
          }}
        >
          {saleswomanCouponCode}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderDefault = () => (
    <img
      src={couponImage}
      style={{
        padding: theme.checkout.padding,
        textAlign: 'center',
        width: '100%',
      }}
      alt="Logo"
    />
  );

  return saleswomanCouponCode ? renderSaleswoman() : renderDefault();
};

export default SomaSalesCoupon;
