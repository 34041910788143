import { makeStyles } from '@material-ui/core';

const useButtonStyle = (theme) => ({
  button: {
    width: 368,
    height: 48,
    margin: '0 0 15px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.checkout.button.shape.borderRadius,
    '&:hover': { backgroundColor: theme.palette.primary.dark },

    '@media(orientation: portrait)': {
      width: 275,
      margin: '0 0 15px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.checkout.button.shape.borderRadius,
      '&:hover': { backgroundColor: theme.palette.primary.dark },
    },
  },
  label: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    lineHeight: '16px',
    textTransform: 'none',

    '@media(orientation: portrait)': {
      fontSize: 14,
    },
  },
  icon: {
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
});

const shareIconButtonStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    width: '100%',
    height: '100%',
    borderRadius: theme.checkout.shareButton.shape.borderRadius,
    marginLeft: 20,
    marginBottom: 15,

    '@media(orientation: portrait)': {
      border: 'none',
      width: '100%',
      height: '100%',
      borderRadius: theme.checkout.shareButton.shape.borderRadius,
      marginLeft: 10,
    },
  },
}));

const useGridContainerStyles = makeStyles(() => ({
  root: {
    marginTop: 14,
    '@media(orientation: portrait)': {
      marginTop: 10,
      marginLeft: 25,
    },
  },
}));

const useBuyButtonGridStyles = makeStyles(() => ({
  root: {
    height: 48,
    marginTop: 34,
    width: 368,
    '@media(orientation: portrait)': {
      minWidth: 182,
      height: 48,
      marginTop: 30,
    },
  },
}));

const useGridItemStyles = makeStyles(() => ({
  root: {
    padding: '0 16px',
    height: 30,
  },
}));

const useShareButtonGridStyles = makeStyles(() => ({
  root: {
    height: 17,
    width: 17,
    marginTop: 34,
    marginRight: 20,
    '@media(orientation: portrait)': {
      height: 48,
      width: 48,
      marginTop: 30,
    },
  },
}));

const useGridContainerInfoStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',

    '@media(orientation: portrait)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));

const useGridPriceInfoStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media(orientation: portrait)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
}));

const useBuyButtonContainerStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,

    '@media(orientation: portrait)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 48,
    },
  },
}));

const useInfoCheckoutStyles = makeStyles(() => ({
  root: {
    padding: '0 16px',
  },
}));

export default {
  useButtonStyle,
  useGridContainerStyles,
  useGridItemStyles,
  useShareButtonGridStyles,
  useBuyButtonGridStyles,
  shareIconButtonStyles,
  useGridContainerInfoStyles,
  useGridPriceInfoStyles,
  useBuyButtonContainerStyles,
  useInfoCheckoutStyles,
};
