import { makeStyles } from '@material-ui/core';

const buttonStyle = (theme) => ({
  opacity: 0.7,
  width: 48,
  height: 48,
  minWidth: 48,
  padding: 0,
  borderColor: 'transparent',
  borderRadius: theme.mutePlayer.borderRadius,

  '@media (orientation: portrait)': {
    minWidth: '20px',
    minHeight: '20px',
    width: '24px',
    height: '24px',
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
});

const useMuteImageSizeStyle = makeStyles({
  root: {
    width: '34.05px',
    height: '34.65px',
    '@media(orientation: portrait)': {
      width: '24px',
      height: '24px',
    },
  },
});

export default { buttonStyle, useMuteImageSizeStyle };
