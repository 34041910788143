import * as Prismic from '@prismicio/client';
import config from '../config';

export const client = Prismic.createClient('somalive-prd', {
  accessToken: `${config.prismicAccessToken}`,
});

const getCorrectEnvironment = (envName) =>
  ({
    production: 'prod',
    development: 'dev',
  }[envName]);

export const GetPrismicDocument = (streamContent, response) => {
  const currentEnviroment = getCorrectEnvironment(config.environment);
  const uid = `${streamContent?.brand.name}${currentEnviroment}`;
  const prismicCurrentDocument = response?.results.find((x) => x.uid === uid);
  return prismicCurrentDocument;
};

export const GetPrismicMeasureDocument = (streamContent, response) => {
  const uid = `medida${streamContent?.brand.name}`;
  const prismicCheckUid = response?.results.find((y) => y.uid === uid);
  const isDevelop = prismicCheckUid?.data.is_develop;
  const isProduction = prismicCheckUid?.data.is_production;
  const environmentCheck = config.environment === 'development' ? isDevelop : isProduction;
  const prismicCurrentDocument = prismicCheckUid?.data.tabela_medidas_apresentadores;
  return environmentCheck ? prismicCurrentDocument : null;
};

const PrismicCountdownDateFormatter = (streamContent, response) => {
  const data = GetPrismicDocument(streamContent, response)?.data.countdown_date[0].text;
  const formatedData = data?.split('-').reverse().join('-');
  return formatedData;
};

export const GetPrismicCountdownValue = (streamContent, response) => {
  const prismicCurrentDocument = GetPrismicDocument(streamContent, response);
  const countdownFormatedDate = PrismicCountdownDateFormatter(streamContent, response);
  const countdownFormatedHour = prismicCurrentDocument?.data.countdown_hour[0].text;
  const prismicCountdownValue = `${countdownFormatedDate}T${countdownFormatedHour}`;
  return prismicCountdownValue;
};
