/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Box } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import Player from '@vimeo/player';
import { isMobile, isIOS } from 'react-device-detect';
import { useEvent } from '../../../events/useEvent';
import { usePlayerWrapper } from './styles';
import { useStreamContext } from '../../../contexts/streamContext';
import { useEventDispatch, somaEvents } from '../../../events';

const VimeoPlayer = ({ volume, rerunStartTime, isPlaying, setIsPlaying }) => {
  const userInteracted = useRef(false);
  const dispatchEvent = useEventDispatch();
  const playerWrapper = usePlayerWrapper();
  const { streamContent } = useStreamContext();
  const [hasVideo, setHasVideo] = useState(true);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);
  const [hasIframe, setHasIframe] = useState(false);

  const simulateUserInteract = () => {
    if (!userInteracted.current) {
      window.addEventListener(
        'click',
        () => {
          userInteracted.current = true;
        },
        { once: true }
      );
    }
  };

  const onPlay = () => {
    dispatchEvent(somaEvents.onVideoStart);
    if (vimeoPlayer) {
      vimeoPlayer.play();
      vimeoPlayer.setVolume(0);
      setIsPlaying(true);
      if (streamContent.video.isRerun === 1) vimeoPlayer.setCurrentTime(rerunStartTime);
    }
  };

  const isAndroid = !isIOS;

  const androidDevice = (player) => {
    if (isAndroid) {
      player.play();
      setIsPlaying(false);
    }
  };

  const iosDevice = (player) => {
    if (isMobile && isIOS) {
      player.play();
      setIsPlaying(true);
    }
  };

  const onCreatePlayer = (url) => {
    try {
      if (url) {
        const player = new Player('vimeo-player', {
          id: url,
          autoplay: false,
          controls: streamContent.video.isRerun !== 0,
          muted: false,
          title: false,
          pip: true,
        });

        androidDevice(player);
        iosDevice(player);

        player.play();
        setIsPlaying(true);
        setVimeoPlayer(player);
      }
    } catch (error) {
      console.error(error);
      setHasVideo(false);
    }
  };

  function getIframeWidth() {
    const vimeoPlayerDiv = document.getElementById('vimeo-player');
    if (vimeoPlayerDiv) {
      const iframe = vimeoPlayerDiv.querySelector('iframe');

      if (iframe) {
        const width = iframe.offsetWidth;
        const player = vimeoPlayerDiv.offsetWidth;

        if (width) {
          onPlay();
          setIsPlaying(true);
        }

        return { width, player };
      }
      return null;
    }

    return null;
  }

  if (volume === 1) {
    setTimeout(() => {
      setHasIframe(true);
    }, 500);
  }

  if (!hasIframe) {
    getIframeWidth();
  }

  const onLoadPlayer = () => {
    vimeoPlayer.on('loaded', () => {
      const iframePlayer = document.querySelector('#vimeo-player iframe');
      iframePlayer.style.top = 0;
      iframePlayer.style.left = 0;
      iframePlayer.style.width = '100%';
      iframePlayer.style.height = '100%';
      iframePlayer.style.overflow = 'hidden';
      iframePlayer.style.position = 'absolute';
    });
  };

  const getPIP = () => {
    if (userInteracted.current === true) {
      vimeoPlayer.requestPictureInPicture();
    }
  };

  useEvent(somaEvents.onActivePip, () => getPIP());

  useEffect(() => {
    const isRerun = streamContent.video.isRerun === 1;

    if (isRerun) {
      onCreatePlayer(streamContent.video.url);
    } else {
      onCreatePlayer(streamContent.video.vimeo_id);
    }

    simulateUserInteract();
  }, [isPlaying]);

  useEffect(() => {
    if (vimeoPlayer !== null) {
      vimeoPlayer.setVolume(volume);
    }
  }, [volume]);

  useEffect(() => {
    if (vimeoPlayer !== null) {
      onLoadPlayer();

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          vimeoPlayer.exitPictureInPicture();
        } else {
          vimeoPlayer.requestPictureInPicture();
        }
      });
    }
  }, [vimeoPlayer, dispatchEvent]);

  return (
    <Box classes={playerWrapper}>
      {hasVideo && <div id="vimeo-player" muted playsInline />}
      {!hasVideo && <div id="no-player" />}
    </Box>
  );
};

export default VimeoPlayer;
