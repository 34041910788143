import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    width: 'calc(100% - 15px)',
    height: '32',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    backgroundColor: 'transparent',
    '@media(orientation: landscape)': {
      alignItems: 'flex-start',
    },
    '@media(orientation: portrait)': {
      height: '34px',
    },
  },
}));

const useCloseButtonStyle = makeStyles({
  root: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(orientation: landscape)': {
      alignSelf: 'self-start',
      justifyContent: 'flex-end',
      marginRight: 10,
      marginTop: 10,
      padding: 0,
    },
    '@media(orientation: portrait)': {
      padding: 10,
      margin: 0,
      justifyContent: 'flex-end',
    },
  },
});

const useArrowButtonStyle = makeStyles({
  root: {
    height: 14,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(orientation: landscape)': {
      alignSelf: 'self-start',
      justifyContent: 'flex-start',
      marginLeft: 49,
      marginTop: 10,
      padding: 0,
    },
    '@media(orientation: portrait)': {
      padding: 10,
      height: 34,
      margin: 0,
      justifyContent: 'flex-start',
    },
  },
  label: {
    width: 0,
    height: 0,
  },
});

const useCloseButtonContainerStyle = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
});

const useArrowButtonContainerStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

export default {
  useBoxStyle,
  useCloseButtonStyle,
  useArrowButtonStyle,
  useCloseButtonContainerStyle,
  useArrowButtonContainerStyle,
};
