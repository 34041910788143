import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    height: 41,
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const useTitleClasses = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.primary.light,
  },
}));

const useTextClasses = makeStyles({
  root: {
    fontSize: 14,
    lineHeight: '16px',
  },
});

const useFormattedPricesClasses = ({ colorValue }) =>
  makeStyles((theme) => ({
    root: {
      color: colorValue,
      fontSize: 14,
      lineHeight: '16px',
      textTransform: theme.products.price.textTransform,
    },
  }))();

export default {
  useBoxStyle,
  useTitleClasses,
  useFormattedPricesClasses,
  useTextClasses,
};
