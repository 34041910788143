import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './styles';

const CountProduct = (props) => {
  const { number, onClickPlus, onClickMinus } = props;
  const buttonClasses = Styles.useButtonStyle();
  const boxClasses = Styles.useBoxStyle();
  const textClasses = Styles.useTextStyle();

  return (
    <Box classes={boxClasses}>
      <Button classes={buttonClasses} disableRipple onClick={onClickMinus} disabled={number === 1}>
        <Typography color="primary" style={{ fontSize: 11 }}>
          -
        </Typography>
      </Button>
      <Typography classes={textClasses}>{`${number} un`}</Typography>
      <Button classes={buttonClasses} disableRipple onClick={onClickPlus}>
        <Typography color="primary" style={{ fontSize: 10 }}>
          +
        </Typography>
      </Button>
    </Box>
  );
};

CountProduct.defaultProps = {
  number: 1,
};

CountProduct.propTypes = {
  number: PropTypes.number,
  onClickPlus: PropTypes.func.isRequired,
  onClickMinus: PropTypes.func.isRequired,
};

export default CountProduct;
