import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
}));

const useButtonStyle = makeStyles((theme) => ({
  root: {
    minWidth: 24,
    minHeight: 24,
    width: 40,
    height: 40,
    fontSize: 18,
    margin: 0,
    borderRadius: theme.quantitySelector.borderRadius,
    border: `1px solid ${theme.palette.secondary.dark}`,
    '&:hover': {
      backgroundColor: theme.quantitySelector.backgroundColor,
    },
  },
}));

const useTextStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 12,
    margin: '12px 1px 12px 12px',
    lineHeight: 1,
    width: 35,
  },
}));

export default { useButtonStyle, useBoxStyle, useTextStyle };
