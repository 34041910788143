import { Box, CardHeader, Container, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';

import { useSocketContext } from '../../../contexts/socketContext';
import { useStreamContext } from '../../../contexts/streamContext';
import { ComposedButton } from '../../molecules/SomaClickable';
import Poll from '../Poll';
import Styles from './styles';

const SomaSurvey = () => {
  const { surveyQuestion } = useSocketContext();
  const { streamContent } = useStreamContext();
  const { brand } = streamContent;
  const theme = useTheme();
  const cardHeaderClasses = Styles.useCardHeaderStyle(theme);
  const emptyBoxClasses = Styles.useEmptyBoxStyle();
  const buttonClasses = Styles.useButtomStyle(theme);
  const portrait = useMediaQuery('(orientation: portrait)');
  const subtextClasses = Styles.useSubtextStyle(theme);
  const [tabValue, setTabValue] = useState(0);

  const endedSurveys =
    surveyQuestion?.filter((survey) => !!Object.hasOwn(survey, 'active') && !survey.active).reverse() || [];
  const activeSurveys = surveyQuestion?.filter((survey) => survey.active) || [];

  const surveyTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const hasEmptyImg = theme.survey.emptyIcon.src;

  const EmptySurvey = () => (
    <>
      <ComposedButton
        figure={
          hasEmptyImg ? (
            <img
              src={theme.survey.emptyIcon.src}
              alt={theme.survey.emptyIcon.alt}
              style={{ margin: portrait && '0 0 0 -12px', width: '96px' }}
            />
          ) : null
        }
        buttonProps={{ disableElevation: true, disableRipple: true, disableFocusRipple: true }}
        buttonStyles={{ ...buttonClasses.composed }}
        labelStyles={{
          ...buttonClasses.label,
          textAlign: 'initial',
          whiteSpace: 'break-spaces',
          marginTop: '24px',
        }}
        gridStyles={{ justifyContent: 'flex-start' }}
        label={tabValue === 0 ? theme.survey.emptySurvey.text : theme.survey.emptyResult.text}
      />
      <Typography classes={subtextClasses}>
        {tabValue === 0 ? theme.survey.emptySurvey.subtext : theme.survey.emptyResult.subtext}
      </Typography>
    </>
  );

  const getWinningOptions = () =>
    endedSurveys.map((survey) => {
      const listOfPorcetages = survey.options.map((option) => option.porcentQuestion);
      const highestValue = Math.max(...listOfPorcetages);
      const winnerOptionIndex = listOfPorcetages.indexOf(highestValue);

      return winnerOptionIndex;
    });

  const renderSurveys = () => {
    if (tabValue === 0) {
      if (activeSurveys.length === 0) return <EmptySurvey />;
      return activeSurveys.map((survey, index) => <Poll key={survey.id} surveyQuestion={survey} index={index + 1} />);
    }

    if (endedSurveys.length === 0) return <EmptySurvey />;
    const listOfWinners = getWinningOptions();
    return endedSurveys.map((survey, index) => (
      <Poll
        surveyQuestion={survey}
        key={survey.id}
        index={endedSurveys.length - index}
        hasEnded
        winnerIndex={listOfWinners[index]}
      />
    ));
  };

  useEffect(() => {
    if (activeSurveys.length === 0 && endedSurveys.length > 0) setTabValue(1);
  }, [activeSurveys.length, endedSurveys.length]);

  return (
    <Container classes={emptyBoxClasses}>
      <CardHeader
        title={theme.survey.emptySurvey.title}
        subheader={theme.survey.emptySurvey.subtitle}
        classes={cardHeaderClasses}
      />
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={surveyTabChange}
        style={{ borderBottom: '1px solid #E5E5E5', marginBottom: '22px' }}
      >
        <Tab
          label={
            <ComposedButton
              buttonProps={{ disableElevation: true, disableRipple: true, disableFocusRipple: true }}
              figure={
                brand.name !== 'farm' && portrait ? null : (
                  <img
                    src={theme.survey.onGoingIcon.src}
                    alt={theme.survey.onGoingIcon.alt}
                    style={{ marginRight: '16px', width: '32px' }}
                  />
                )
              }
              gridProps={{ wrap: 'nowrap', justifycontent: 'center' }}
              labelStyles={{ whiteSpace: 'nowrap', lineHeight: '24px' }}
              label={theme.survey.onGoingIcon.alt}
            />
          }
          value={0}
        />
        <Tab
          label={
            <ComposedButton
              buttonProps={{ disableElevation: true, disableRipple: true, disableFocusRipple: true }}
              figure={
                brand.name !== 'farm' && portrait ? null : (
                  <img
                    src={theme.survey.resultsIcon.src}
                    alt={theme.survey.resultsIcon.alt}
                    style={{ marginRight: '16px', width: '32px' }}
                  />
                )
              }
              labelStyles={{ lineHeight: '24px' }}
              gridProps={{ wrap: 'nowrap', justifyContent: 'center' }}
              label={theme.survey.resultsIcon.alt}
            />
          }
          value={1}
        />
      </Tabs>
      <Box style={{ flex: 1, overflow: 'auto' }}>{renderSurveys()}</Box>
    </Container>
  );
};

export default SomaSurvey;
