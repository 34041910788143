import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, CardHeader } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { usePrismicDocuments } from '@prismicio/react';
import Styles from './styles';
import { useStreamContext } from '../../../contexts/streamContext';
import { GetPrismicMeasureDocument } from '../../../services/prismic';

const Anchor = () => {
  const theme = useTheme();
  const { streamContent } = useStreamContext();
  const [response] = usePrismicDocuments();
  const cardHeaderClasses = Styles.useCardHeaderStyle(theme);
  const cardMediaClasses = Styles.useCardMediaStyle();
  const cardStyleClasses = Styles.useCardStyle(theme);
  const cardContentClasses = Styles.useCardContentStyle();
  const typographyTitleClasses = Styles.useTypographyTitleStyle();
  const typographyNameClasses = Styles.useTypographyNameStyle(theme);
  const typographyValueClasses = Styles.useTypographyValueStyle();
  const gridCardInfoClasses = Styles.useGridCardInfoStyle();
  const gridCardContentClasses = Styles.useGridCardContentStyle();
  const boxContentClasses = Styles.useBoxContentStyle();
  const infoValueContentClasses = Styles.useInfoValuesStyle();
  const unitOfMeasure = Styles.useUnitOfMeasureStyle();
  const [measures, setMeasures] = useState();

  useEffect(() => {
    const measurePrismicData = GetPrismicMeasureDocument(streamContent, response);
    setMeasures(measurePrismicData);
  }, [streamContent, response]);

  return (
    <Box classes={boxContentClasses}>
      <CardHeader
        title={theme.anchorSize.titleAnchorSize}
        subheader={theme.anchorSize.subTitleAnchorSize}
        classes={cardHeaderClasses}
      />
      <Grid style={{ overflow: 'auto' }}>
        {measures?.map((measureData) => (
          <Grid classes={gridCardContentClasses} key={measureData.name[0]?.text} container>
            <CardMedia classes={cardMediaClasses} component="img" src={measureData.imagem_apresentador.url} />
            <Card classes={cardStyleClasses}>
              <CardContent classes={cardContentClasses}>
                <Typography classes={typographyNameClasses}>{measureData.name[0]?.text}</Typography>
                <Grid classes={gridCardInfoClasses}>
                  <Box classes={infoValueContentClasses}>
                    <Typography classes={typographyTitleClasses} style={{ justifyContent: 'flex-start' }}>
                      {measureData.size[0]?.text}
                    </Typography>
                    <Typography classes={typographyValueClasses}>veste</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{measureData.bust[0]?.text}</Typography>
                      <Typography classes={unitOfMeasure}>cm</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses}>busto</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{measureData.waist[0]?.text}</Typography>
                      <Typography classes={unitOfMeasure}>cm</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses}>cintura</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{measureData.hips[0]?.text}</Typography>
                      <Typography classes={unitOfMeasure}>cm</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses}>quadril</Typography>
                  </Box>
                  <Box classes={infoValueContentClasses}>
                    <Grid classes={typographyTitleClasses}>
                      <Typography>{measureData.height[0]?.text}</Typography>
                      <Typography classes={unitOfMeasure}>m</Typography>
                    </Grid>
                    <Typography classes={typographyValueClasses} style={{ textAlign: 'right' }}>
                      altura
                    </Typography>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Anchor;
