import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { usePrismicDocuments } from '@prismicio/react';
import { useStreamContext } from '../../../contexts/streamContext';
import { GetPrismicCountdownValue } from '../../../services/prismic';
import { useCountdown } from '../../../hooks/useCountdown';
import Styles from './styles';

const CountdownItem = (props) => {
  const { label, value } = props;
  const theme = useTheme();

  const countdownValueClasses = Styles.useCountdownValueStyles(theme);
  const countdownLabelClasses = Styles.useCountdownLabelStyles(theme);
  const wrapperItemClasses = Styles.useWrapperItemStyles();

  return (
    <Grid classes={wrapperItemClasses} item xs={2}>
      <Typography component="span" variant="h4" classes={countdownValueClasses}>
        {value}

        <Typography component="p" variant="subtitle2" classes={countdownLabelClasses}>
          {label}
        </Typography>
      </Typography>
    </Grid>
  );
};

const CountdownSeparator = () => {
  const theme = useTheme();
  const separatorClasses = Styles.useSeparatorStyles(theme);

  return (
    <Grid item xs={1} classes={separatorClasses}>
      <Typography component="span" variant="h4" align="center">
        :
      </Typography>
    </Grid>
  );
};

const CountDown = () => {
  const [countdownValue, setCountdownValue] = useState();
  const countDownClasses = Styles.useCountDownStyles();
  const countdowGridClasses = Styles.useCountdownGridStyles();
  const [days, hours, minutes, seconds] = useCountdown(countdownValue);
  const liveTimeOut = days > -1 && hours > -1 && minutes > -1 && seconds > -1;
  const timeOut = !!liveTimeOut;
  const { streamContent } = useStreamContext();
  const [response] = usePrismicDocuments();

  useEffect(() => {
    const prismicCountdown = GetPrismicCountdownValue(streamContent, response);
    setCountdownValue(prismicCountdown);
  }, [response, streamContent]);

  return (
    <>
      {timeOut && (
        <Grid classes={countdowGridClasses}>
          <Grid classes={countDownClasses}>
            <CountdownItem label="dias" value={days} />
            <CountdownSeparator />
            <CountdownItem label="horas" value={hours} />
            <CountdownSeparator />
            <CountdownItem label="minutos" value={minutes} />
            <CountdownSeparator />
            <CountdownItem label="segundos" value={seconds} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CountDown;
