import { makeStyles } from '@material-ui/core';

const useBoxStyle = makeStyles({
  root: {
    paddingTop: 13,
    paddingBottom: 25,
    overflow: 'hidden',
    position: 'relative',
    '@media(orientation: portrait)': {
      paddingRight: 5,
    },
    width: '100%',
    '@media(orientation: landscape)': {
      paddingTop: 0,
      marginTop: 18,
      width: 430,
      marginLeft: 24,
    },
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
});
const useboxTitleStyle = () =>
  makeStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '@media(orientation: portrait)': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
  })();

const useMainStyle = makeStyles({
  root: {
    width: '100%',
    overflow: 'hidden',
    overflowY: 'hidden',
    '@media (orientation: portrait)': {
      overflowY: 'hidden',
    },
    '@media (orientation: landscape)': {
      width: 430,
      height: '53vh',
      overflowY: 'scroll',
      '&::scrollbar': {
        '@media (orientation: portrait)': {
          display: 'none',
          maxHeight: 'calc(100vh - 364px)',
        },
      },
      '&::-webkit-scrollbar': {
        '@media (orientation: portrait)': {
          display: 'none',
          maxHeight: 'calc(100vh - 364px)',
        },
      },
    },
  },
});

const useFooterStyle = () =>
  makeStyles({
    root: {
      flex: '0 0 auto',
      backgroundColor: '#fff',
      paddingBottom: 5,
      borderTop: '2px solid #f5f5f5',
      position: 'fixed',
      bottom: 30,
      width: 420,
      '@media(orientation: portrait)': {
        paddingRight: 20,
        width: '100%',
        position: 'relative',
        bottom: 0,
      },
    },
  })();

const useEmptyBoxStyle = (theme) =>
  makeStyles({
    root: {
      padding: '24px 0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '@media(orientation: portrait)': {
        padding: '19px 26px',
        justifyContent: 'flex-start',
        '& .center-container': {
          marginLeft: theme.bag.button.shape.marginLeft,
        },
      },
      '& .center-container': {
        padding: '0px',
        width: '217px',
        marginTop: '21px',
      },
    },
  })();

const useButtomStyle = (theme) => ({
  composed: {
    cursor: 'default',
    padding: 0,
  },
  label: {
    fontSize: 24,
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    fontFamily: theme.bag.emptyTextStyle.fontFamily,
  },
  basic: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    borderRadius: theme.bag.continueShopping.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const useTitleStyles = () =>
  makeStyles({
    root: {
      fontSize: 24,
      fontWeight: 400,
      padding: '0px 24px 20px 0px',

      '@media(orientation: portrait)': {
        fontSize: 24,
        lineHeight: 1,
        fontWeight: 400,
        padding: '0px 24px 20px 16px',
      },
    },
  })();

const useTotalProductsStyle = makeStyles((theme) => ({
  root: {
    padding: '0px 0px 13px 16px',
    color: theme.palette.primary.light,
    lineHeight: 1,

    '@media (orientation: portrait)': {
      padding: '0px 24px 24px 16px',
      color: theme.palette.primary.light,
    },
  },
}));

export default {
  useBoxStyle,
  useEmptyBoxStyle,
  useMainStyle,
  useFooterStyle,
  useButtomStyle,
  useboxTitleStyle,
  useTitleStyles,
  useTotalProductsStyle,
};
