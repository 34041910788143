import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import { InfoCheckout } from '../../atoms';
import SomaInstallmentText from '../InstallmentText';
import Styles from './style';
import { CartSimulationContext, SalesWomanContext } from '../../../hooks/contexts';
import { useEventDispatch, somaEvents, errorTypes } from '../../../events';
import { getDevice } from '../../../helpers/eventHelper';
import { useSnackbarContext } from '../../../contexts/snackBarContext';
import TextButton from '../SomaClickable/textButton';
import ImportantWarning from '../../atoms/ImportantWarning';
import { useStreamContext } from '../../../contexts/streamContext';

const Checkout = (props) => {
  const { discountValue, subtotalValue, totalValue, onCheckout, portrait, bagUrl } = props;
  const [installments, setInstallments] = useState();

  const theme = useTheme();
  const { streamContent } = useStreamContext();
  const { brand } = streamContent;
  const { saleswomanCouponCode } = useContext(SalesWomanContext);
  const cartSimulationContext = useContext(CartSimulationContext);
  const buttonClasses = Styles.useButtonStyle(theme);
  const { sendSnack } = useSnackbarContext();

  const dispatchEvent = useEventDispatch();

  useEffect(() => {
    const installmentOptions = cartSimulationContext?.paymentData?.installmentOptions;

    if (!installmentOptions || installmentOptions.length === 0) return;

    try {
      const getInstallmentOptions = installmentOptions;
      const installmentsCount = getInstallmentOptions.map((prod) => ({
        installments: prod.installments[prod.installments.length - 1].count,
        value: prod.installments[prod.installments.length - 1].value / 100,
      }));
      const maxInstallments = installmentsCount.reduce((prevIns, currIns) =>
        prevIns.installments > currIns.installments ? prevIns : currIns
      );
      setInstallments(maxInstallments);
    } catch (error) {
      dispatchEvent(somaEvents.onError, {
        type: errorTypes.requestError,
        message: error.message,
        path: 'Checkout/index.js -> setInstallments()',
        object: cartSimulationContext,
        device: getDevice(),
        stack: error.stack,
      });
      setInstallments({});
    }
  }, [cartSimulationContext, dispatchEvent]);

  const { discountText, totalText, subtotalText } = theme.checkout;
  const copyBagUrl = () => {
    window.navigator.clipboard.writeText(bagUrl);
    dispatchEvent(somaEvents.onCartSharingLinkCopied);
    sendSnack('link copiado!', 'success');
  };

  const cupomValue = saleswomanCouponCode || theme.checkout.cupomCodeValue;
  const cupomCodeText = theme.checkout.cupomCodeText.replace('<CUPOMCODEVALUE>', cupomValue.toUpperCase());

  const formattedPrice = (price) =>
    new Intl.NumberFormat(theme.i18n.locale, {
      style: 'currency',
      currency: theme.i18n.currency,
      maximumFractionDigits: 2,
    }).format(price);

  const config = {
    align: portrait ? 'center' : 'right',
  };

  const gridContainerClasses = Styles.useGridContainerStyles();
  const gridItemClasses = Styles.useGridItemStyles();
  const buyButtonGridClasses = Styles.useBuyButtonGridStyles();
  const shareButtonGridClasses = Styles.useShareButtonGridStyles();
  const shareIconButtonClasses = Styles.shareIconButtonStyles();
  const gridContainerInfoClasses = Styles.useGridContainerInfoStyles();
  const gridPriceInfoClasses = Styles.useGridPriceInfoStyles();
  const buyButtonContainerClasses = Styles.useBuyButtonContainerStyles();
  const infoCheckoultClasses = Styles.useInfoCheckoutStyles();

  return (
    <Grid classes={gridContainerClasses}>
      <Grid classes={gridItemClasses}>
        <InfoCheckout
          textValue={subtotalText}
          colorValue={theme.palette.primary.light}
          priceValue={formattedPrice(subtotalValue)}
          noBorder
        />
      </Grid>
      <Grid classes={infoCheckoultClasses} style={{ height: 30 }}>
        <InfoCheckout
          textValue={discountText}
          priceValue={`-${formattedPrice(discountValue)}`}
          colorValue={theme.checkout.discountTextColor}
        />
      </Grid>
      {!!cupomValue && (
        <Grid classes={infoCheckoultClasses}>
          <InfoCheckout contentAlign="center" couponValue={cupomCodeText} />
        </Grid>
      )}
      <Grid classes={gridContainerInfoClasses}>
        <InfoCheckout textValue={totalText} priceValue="" />
        <Grid classes={gridPriceInfoClasses}>
          {installments?.value && (
            <SomaInstallmentText value={totalValue} align={config.align} bagInstallments={installments} />
          )}
        </Grid>
      </Grid>
      <ImportantWarning />
      <Grid classes={buyButtonContainerClasses}>
        <Grid item classes={buyButtonGridClasses}>
          <TextButton
            buttonProps={{
              onClick: onCheckout,
              endIcon: brand.name === 'farm' ? null : <ArrowForwardIosIcon style={{ ...buttonClasses.icon }} />,
            }}
            buttonStyles={{ ...buttonClasses.button }}
            labelStyles={{ ...buttonClasses.label }}
            label={`${theme.checkout.button.checkoutText} · R$ ${formattedPrice(totalValue)
              .replace(/R\$/g, '')
              .trim()}`}
          />
        </Grid>
        <Grid item classes={shareButtonGridClasses}>
          <IconButton classes={shareIconButtonClasses} onClick={copyBagUrl}>
            <img src={theme.products.shareIcon.src} alt="compartilhar" height="17px" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

Checkout.propTypes = {
  totalValue: PropTypes.number.isRequired,
  subtotalValue: PropTypes.number.isRequired,
  discountValue: PropTypes.number.isRequired,
};

export default Checkout;
