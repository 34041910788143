import { makeStyles } from '@material-ui/core';

const aspectRatio = '56.25%';

export const useContentStyle = () =>
  makeStyles({
    root: {
      height: '100%',
      width: '100%',

      '@media (orientation: portrait)': {
        minHeight: '90vh',
        overflow: 'hidden',
        flexDirection: 'column',
      },
    },
  })();

export const useMainStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
  },
});

export const useVideoStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
    paddingBottom: aspectRatio,

    '@media (orientation: landscape)': {
      flex: 1,
      order: 1,
      height: '100%',
      paddingBottom: '0',
    },
  },
});

export const useDesktopMenuStyles = (widthMenuDesk) =>
  makeStyles((theme) => ({
    root: {
      height: '100%',
      maxWidth: widthMenuDesk > theme.menu.width ? widthMenuDesk : theme.menu.width,
      backgroundColor: '#fff',
      zIndex: 1400,
    },
  }))();

export const useMuteAndPipStyles = makeStyles({
  root: {
    position: 'absolute',
    display: 'flex',
    right: 16,
    top: 16,
    gap: 12,
  },
});

export const useReactButtonStyles = makeStyles({
  root: {
    position: 'absolute',
    display: 'flex',
    left: 24,
    bottom: 20,
    gap: 10,
  },
});

export const useChatAndReactionBoxStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 16,
    position: 'absolute',
    right: 20,
    flexDirection: 'column',
    bottom: '9%',

    '@media(orientation: portrait)': {
      right: 16,
      top: 12,
      gap: 12,
    },
  },
});

export const useChatAndReactionBoxMobileStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 16,
    position: 'absolute',
    right: 20,
    flexDirection: 'column',
    bottom: '9%',

    '@media(orientation: portrait)': {
      right: 16,
      top: 12,
      gap: 12,
    },
  },
});

export const useMobileMenuStyles = makeStyles({
  root: {
    overflow: 'auto',
    zIndex: 50,
    flexBasis: 'auto',
    flex: 1,
  },
});

export const useReactionStyles = makeStyles({
  root: {
    right: '7%',
    bottom: '7%',
    '@media (orientation: portrait)': {
      right: '0',
    },
  },
});
